import React from "react";
import './footer.css';
import './Media.css';
import year from './img/49-Years-Logo.webp';
import logo from './img/2024Logos_150x132px.webp';
import fb from './img/fb.webp';
import twit from './img/twit.webp';
import yout from './img/yout.webp';
import inst from './img/inst.webp';


function Footer() {
    return (
        <div className="footer-container">
            <div class="footer_body">
                <div className="footer_col1"><img src={logo} alt="Aqua Technics Fibreglass Swimming Pools" /></div>
                <div className="footer_col2"><div className="social-container">
                    <div className="social_col1"><a href="https://www.facebook.com/aquatechnicspool/" target="_blank" rel="noreferrer"><img src={fb} alt="Aqua Technics Fibreglass Swimming Pools - Facebook" /></a></div>
                    <div className="social_col1"><a href="https://www.instagram.com/aqua_technics/?hl=en" target="_blank" rel="noreferrer"><img src={inst} alt="Aqua Technics Fibreglass Swimming Pools - Instagram" /></a></div>
                    <div className="social_col1"><a href="https://twitter.com/aquatechnicswa" target="_blank" rel="noreferrer"><img src={twit} alt="Aqua Technics Fibreglass Swimming Pools - Twitter" /></a></div>
                    <div className="social_col1"><a href="https://www.youtube.com/channel/UCpQ5KZ47VLTYPC-9pHTdLiw" target="_blank" rel="noreferrer"><img src={yout} alt="Aqua Technics Fibreglass Swimming Pools - Youtube" /></a></div>
                </div></div>
                <div className="footer_col3"><img src={year} alt="Aqua Technics Fibreglass Swimming Pools" /></div>

            </div>

        </div>
    )
}

export default Footer;